import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"

const Wrapper = styled(motion.footer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${rem(20)};
  display: flex;
  justify-content: space-between;

  @media screen and ${props => props.theme.mobileDown} {
    span {
      white-space: nowrap;
      font-size: ${rem(13)};
    }
  }
`

const Footer = props => {
  return (
    <React.Fragment>
      <Wrapper
        initial={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <span>vytis & garla dviračių taisykla</span>
        <span>(C) {new Date().getFullYear()}</span>
      </Wrapper>
    </React.Fragment>
  )
}

export default Footer
