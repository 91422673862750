import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"

import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"

const InnerWrapper = styled.div`
  opacity: 0;
  transform: translateY(${rem(-15)});

  @media screen and ${props => props.theme.mobileUp} {
    padding: ${rem(25)};
    padding-bottom: ${rem(15)};
  }

  @media screen and ${props => props.theme.mobileDown} {
    padding: ${rem(20)};
    padding-bottom: ${rem(10)};
  }
`

const Borders = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 5px;
  left: -5px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &::before {
    width: 1px;
    height: 100%;
    background: #000;
    height: 0%;
  }

  &::after {
    width: 100%;
    height: 1px;
    background: #000;
    width: 0%;
  }
`

const Info = styled.div`
  z-index: 3;

  @media screen and ${props => props.theme.mobileUp} {
    width: ${rem(360)};
    position: absolute;
    bottom: 12.56%;
    left: 0;
  }

  @media screen and ${props => props.theme.mobileDown} {
    position: relative;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background: #f1f1f1;
    transition: 0.75s cubic-bezier(0.87, -0.01, 0.08, 0.98);
    z-index: -1;
  }

  ${props =>
    props.loaded &&
    css`
      &::after {
        height: 100%;
        transition: 0.75s 0.8s cubic-bezier(0.87, -0.01, 0.08, 0.98);
      }

      ${InnerWrapper} {
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s 1.5s;
      }

      ${Borders} {
        &::before {
          height: 100%;
          transition: 1s 1.5s;
        }

        &::after {
          width: 100%;
          transition: 1s 1.5s;
        }
      }
    `}
`

const TimeRow = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
  height: 1.2;
`

const Links = styled.div`
  margin-top: ${rem(20)};

  & > div {
    line-height: 1.2;
    height: 1.2;
  }

  a {
    text-decoration: none;
    color: #000;
  }
`

const SocialMedia = styled.div`
  margin-top: ${rem(20)};
  display: flex;

  a {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #000;

    @media screen and ${props => props.theme.mobileDown} {
      margin-right: ${rem(10)};
    }
  }
`

const InfoBox = props => {
  return (
    <Info loaded={props.loaded}>
      <InnerWrapper>
        {props.times.map((item, index) => (
          <TimeRow key={index}>
            <span>{item.text}</span>
            <span>{item.value}</span>
          </TimeRow>
        ))}

        <Links>
          {props.info.map((item, index) => (
            <div key={index}>
              <a key={index} href={item.url} target="_blank" rel="noreferrer">
                {item.text}
              </a>
            </div>
          ))}
        </Links>

        <SocialMedia>
          {props.socialMediaLinks.map((item, index) => (
            <a key={index} href={item} target="_blank" rel="noreferrer">
              {item.includes("facebook") && <FacebookIcon />}
              {item.includes("instagram") && <InstagramIcon />}
            </a>
          ))}
        </SocialMedia>
      </InnerWrapper>

      <Borders />
    </Info>
  )
}

export default InfoBox
