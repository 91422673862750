import React from "react"
import "reset-css"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import theme from "../theme"

import "../assets/stylesheets/site.scss"

import Seo from "../components/seo"
import Nav from "../components/nav"
import Footer from "../components/footer"
import Content from "../components/content"

const Container = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media screen and ${props => props.theme.mobileDown} {
    height: 100%;
  }
`

const IndexPage = props => {
  const content = {
    times: [
      {
        text: "I-V",
        value: "10:00 - 19:00",
      },
      {
        text: "VI",
        value: "-------------",
      },
      {
        text: "VII",
        value: "-------------",
      },
    ],
    info: [
      {
        text: "Antakalnio g. 93, Vilnius",
        url: "https://goo.gl/maps/1wjeaGHTjFzwrzT18",
      },
      {
        text: "+370 684 52759",
        url: "tel:+37068452759",
      },
      {
        text: "vytisgarla@gmail.com",
        url: "mailto:vytisgarla@gmail.com",
      },
    ],
    socialMediaLinks: [
      "https://www.facebook.com/vytisgarla",
      "https://www.instagram.com/vytisgarla/",
    ],
    images: [props.data.image1, props.data.image2, props.data.image3],
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Seo image={props.data.seoImage} />

        <Nav image={props.data.logo} />

        <Content {...content} />

        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "images/icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 364) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        image1: file(relativePath: { eq: "images/image1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        image2: file(relativePath: { eq: "images/image2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        image3: file(relativePath: { eq: "images/image3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        seoImage: file(relativePath: { eq: "images/meta-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <IndexPage data={data} {...props} />}
  />
)
