import React, { useState } from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { rem } from "polished"

import InfoBox from "./info-box"

const Wrapper = styled.div`
  margin: auto auto;
  position: relative;

  @media screen and ${props => props.theme.mobileUp} {
    width: ${rem(910)};
    max-width: 90%;

    &::after {
      content: "";
      display: block;
      padding-top: 65.55%;
    }
  }

  @media screen and ${props => props.theme.mobileDown} {
    max-width: 80%;
    width: ${rem(360)};
    margin-left: 5%;
  }
`

const Container = styled.div`
  height: 100%;
  display: flex;
`

const StyledImg = styled(Img)``

const ImageOuterContainer = styled.div`
  opacity: 0;
  transition: 1s;
  transform: scale(1.1);
`

const ImageContainer = styled.div`
  position: absolute;
  overflow: hidden;
  transform: translateY(${rem(-30)});
  transition: 0.75s;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -1;
    height: 0;
    transition: 0.75s;
  }

  ${props =>
    props.loaded &&
    css`
      transform: translateY(0);
      transition: 0.75s ${props => props.transitionTime}s;

      &::after {
        height: 100%;
        transition: 1s ${props => props.transitionTime}s
          cubic-bezier(0.87, -0.01, 0.08, 0.98);
      }

      ${ImageOuterContainer} {
        opacity: 1;
        transform: scale(1.001);
      }
    `}
`

const Images = styled.div`
  ${ImageContainer}:nth-of-type(1) {
    width: 59.78%;
    left: 9.5%;
    top: 0;

    @media screen and ${props => props.theme.mobileDown} {
      top: auto;
      bottom: 90%;
      left: -5%;
    }

    &::after {
      background: #a08b7b;
    }

    ${ImageOuterContainer} {
      transition: opacity 0.3s 1.2s, transform 1s 1.2s;
    }
  }

  ${ImageContainer}:nth-of-type(2) {
    width: 32.74%;
    left: 36.59%;
    bottom: 0;

    @media screen and ${props => props.theme.mobileDown} {
      width: 80%;
      top: 20%;
      bottom: auto;
    }

    &::after {
      background: #c1a182;
    }

    ${ImageOuterContainer} {
      transition: opacity 0.3s 1.4s, transform 1s 1.4s;
    }
  }

  ${ImageContainer}:nth-of-type(3) {
    width: 34.94%;
    top: 19.43%;
    right: 0;

    @media screen and ${props => props.theme.mobileDown} {
      left: auto;
      right: -20%;
      bottom: 70%;
      width: 60%;
      top: auto;
    }

    &::after {
      background: #3d4831;
    }

    ${ImageOuterContainer} {
      transition: opacity 0.3s 1.6s, transform 1s 1.6s;
    }
  }
`

const ImageComponent = props => {
  const [loaded, setImageLoaded] = useState(false)

  return (
    <ImageContainer
      transitionTime={props.transitionTime * 0.1 * 2}
      loaded={loaded}
    >
      <ImageOuterContainer>
        <StyledImg
          loaded={loaded}
          onLoad={() => {
            setImageLoaded(true)
            props.checkLoad()
          }}
          fluid={props.image.childImageSharp.fluid}
        />
      </ImageOuterContainer>
    </ImageContainer>
  )
}

const Content = props => {
  const [loaded, setLoaded] = useState(false)

  const checkLoad = () => {
    setLoaded(true)
  }

  return (
    <Container>
      <Wrapper>
        <InfoBox loaded={loaded} {...props} />

        <Images>
          {props.images.map((item, index) => (
            <ImageComponent
              checkLoad={checkLoad}
              transitionTime={index + 1}
              key={index}
              image={item}
            />
          ))}
        </Images>
      </Wrapper>
    </Container>
  )
}

export default Content
