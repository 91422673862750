import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import CallIcon from "@material-ui/icons/Call"

const Wrapper = styled(motion.nav)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: ${rem(20)};
  z-index: 2;

  @media screen and ${props => props.theme.mobileDown} {
    padding: ${rem(10)} ${rem(20)} 0 ${rem(20)};
    display: flex;
    justify-content: space-between;
  }
`

const LogoWrapper = styled.div`
  width: ${rem(100)};
  position: relative;

  @media screen and ${props => props.theme.mobileDown} {
    width: ${rem(50)};
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${rem(70)};
    height: ${rem(70)};
    margin-top: ${rem(-35)};
    margin-left: ${rem(-35)};
    border-radius: 999em;
    background: #ff008e;

    @media screen and ${props => props.theme.mobileDown} {
      width: ${rem(50)};
      width: ${rem(34)};
      height: ${rem(34)};
      margin-top: ${rem(-17)};
      margin-left: ${rem(-17)};
    }
  }
`

const MobileLink = styled.a`
  @media screen and ${props => props.theme.mobileUp} {
    display: none;
  }

  @media screen and ${props => props.theme.mobileDown} {
    align-self: center;
    border: 2px dashed #ff008e;
    display: block;
    color: inherit;
  }
`

const Footer = props => {
  return (
    <React.Fragment>
      <Wrapper
        initial={{ opacity: 0, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <LogoWrapper>
          <Img fluid={props.image.childImageSharp.fluid} />
        </LogoWrapper>

        <MobileLink target="_blank" href="tel:+37068452759">
          <CallIcon />
        </MobileLink>
      </Wrapper>
    </React.Fragment>
  )
}

export default Footer
