import React from "react"
import { Helmet } from "react-helmet"

const Seo = props => {
  const data = {
    lang: `en`,
    title: `Vytis & Garla | dviračių taisykla antakalnyje`,
    description: `Hedder`,
    image: props.image.childImageSharp.fluid.src,
  }

  return (
    <Helmet>
      <title>{data.title}</title>
      <meta name="description" content={data.description} />
      <meta
        itemprop="name"
        itemscope
        itemtype="http://schema.org/WebPage"
        content="Vytis & Garla | dviračių taisykla antakalnyje"
      />
      <meta
        itemprop="description"
        itemscope
        itemtype="http://schema.org/WebPage"
        content={data.description}
      />
      <meta
        itemprop="image"
        itemscope
        itemtype="http://schema.org/WebPage"
        content={data.image}
      />
      <meta property="og:title" content={data.title} />
      <meta property="og:image" content={data.image} />
      <meta name="twitter:title" content="" />
      <meta name="twitter:description" content="" />
      <meta name="twitter:image:src" content={data.image} />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default Seo
