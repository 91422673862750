import colors from "./colors"
import mq from "./mq"

Object.keys(mq).map(function (key, index) {
  mq[key] = `(${mq[key]})`
})

const index = {
  ...colors,
  ...mq,
}

export default index
